(function ($) {
  "use strict";

  $(function () {
    $('[data-toggle="tooltip"]').tooltip({
      placement: "top",
    });

    /***** Carousels ****/
    $("#validate").hide();
    var partnerCarousel = $(".partners .owl-carousel").owlCarousel({
      loop: true,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplaySpeed: 1000,
      mouseDrag: true,
      items: 5,
      responsive: {
        0: {
          items: 2,
        },
        480: {
          items: 2,
        },
        768: {
          items: 3,
        },
        1024: {
          items: 5,
        },
      },
    });
    var galleryCarousel = $("#gallery .owl-carousel").owlCarousel({
      loop: true,
      autoplay: false,
      autoplayTimeout: 5000,
      autoplaySpeed: 1000,
      mouseDrag: true,
      items: 1,
    });
    /***** Carousel Arrows ****/

    $(".partnerArrow").click(function (e) {
      var _this = $(this);
      if (_this.hasClass("left")) {
        partnerCarousel.trigger("prev.owl.carousel");
      }
      if (_this.hasClass("right")) {
        partnerCarousel.trigger("next.owl.carousel");
      }
      e.preventDefault();
    });
    $(".galleryArrow").click(function (e) {
      var _this = $(this);
      if (_this.hasClass("left")) {
        galleryCarousel.trigger("prev.owl.carousel");
      }
      if (_this.hasClass("right")) {
        galleryCarousel.trigger("next.owl.carousel");
      }
      e.preventDefault();
    });
    /***** Action Buttons ****/

    $(".btn-action").click(function () {
      var subject = $(this).attr("data-hidden");
      $("html, body").animate(
        {
          scrollTop: $("#mainBlock").offset().top,
        },
        1500,
        function () {
          $("#registerName").focus();
        }
      );
      if (typeof subject != "undefined") {
        $("#hiddenSubject").attr("value", subject);
      }
    });
    /***** Video ****/

    $(".playVideoBtn").click(function (e) {
      $("#ees-video")[0].play();
      e.preventDefault();
    });
    $("#ees-video").click(function () {
      $("#ees-video")[0].pause();
      $(".playVideoBtn").css("display", "inline-block");
    });
    $("#videoModal").on("hidden.bs.modal", function () {
      $("#ees-video")[0].pause();
      $("#ees-video")[0].currentTime = 0;
    });
    /***** Modal Center ****/

    function reposition() {
      var modal = $(this),
        dialog = modal.find(".modal-dialog");
      modal.css("display", "block");
      dialog.css(
        "margin-top",
        Math.max(0, ($(window).height() - dialog.height()) / 2)
      );
    }
    $(".modal").on("show.bs.modal", reposition);
    $(window).on("resize", function () {
      $(".modal:visible").each(reposition);
    });
  });
})(jQuery);
