import "vendors/bootstrap/bootstrap.css";
import "vendors/bootstrap/bootstrap-theme.css";
import "vendors/bootstrap/bootstrap.js";

import "vendors/owl.carousel/assets/owl.carousel.min.css";
import "vendors/owl.carousel/owl.carousel.min.js";

import "./sass/main.scss";

// import "vendors/purl.js";

// import "vendors/jscrollpane/jquery.jscrollpane.css";
// import "vendors/jscrollpane/jquery.mousewheel.js";
// import "vendors/jscrollpane/jquery.jscrollpane.min.js";

import "./js/main.js";
// import "./js/site-footer.js";
// import "./js/lang-switcher.js";
// import "./js/our-team.js";
// import "./js/home-brands.js";
// import "./js/home-intro.js";
// import "./js/home-animation.js";
// import "./js/single-brand.js";
